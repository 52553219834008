import * as React from "react";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";

import Breadcrumbs from "@components/Breadcrumbs";
import TitleSection from "@components/TitleSection";
import Gallery from "@components/Gallery";
import Button from "@components/Button";
import Seo from "@components/Seo";

import SinglePerson from "@page_components/meet-us/SinglePerson";

function MeetUsPageEN({ data }) {
	const { seo } = data?.page_data?.nodes[0];
	const { page_data } = data;
	// let { gallery_thumbnails, gallery_fullImage } = data;
	// gallery_thumbnails = gallery_thumbnails.nodes[0].pageGallery.gallery;
	// gallery_fullImage = gallery_fullImage.nodes[0].pageGallery.gallery;
	const { featuredImage, title } = page_data.nodes[0];
	const {
		title: title_personel,
		description,
		personel,
	} = page_data.nodes[0].pageMeetUs;

	const image_hero = getImage(featuredImage?.node?.gatsbyImage);
	const breadcrumbs_data = [{ name: "Meet us", href: `/meet-us` }];

	return (
		<>
			<Seo title={seo.title} description={seo.metaDesc} />
			<div className="meet-us">
				<div className="container">
					<Breadcrumbs data={breadcrumbs_data} />

					<section className="meet-us__hero">
						<TitleSection title={title} image={image_hero}>
							<div
								dangerouslySetInnerHTML={{
									__html: description,
								}}
							/>
						</TitleSection>
					</section>
				</div>
				<section className="meet-us__personel">
					<div className="container">
						<h2>{title_personel}</h2>
						<div className="meet-us__personel-list">
							{personel.map((person, index) => {
								return (
									<SinglePerson
										person_data={person}
										float={
											index % 2 !== 0 ? "right" : "left"
										}
										key={person.name}
									/>
								);
							})}
						</div>
					</div>
				</section>

				{/* {gallery_thumbnails?.length > 0 && (
          <section className="meet-us__gallery">
            <div className="container">
              <h2>Gallery</h2>
              <Gallery
                data={{
                  thumbnails: gallery_thumbnails,
                  fullImages: gallery_fullImage,
                }}
              />
              <Button href="/en/gallery/team/">Show more photos</Button>
            </div>
          </section>
        )} */}
			</div>
		</>
	);
}

export default MeetUsPageEN;

export const query = graphql`
	{
		page_data: allWpPage(filter: { title: { eq: "Meet us" } }) {
			nodes {
				title
				pageMeetUs {
					description
					title
					personel {
						personName
						personProfession
						personDescription
						personPhoto {
							gatsbyImage(width: 261, height: 289, fit: FILL)
						}
					}
				}
				featuredImage {
					node {
						gatsbyImage(width: 451, height: 530)
					}
				}
				seo {
					title
					metaDesc
				}
			}
		}
	}
`;
