import "./styles.scss";

import React, { useState } from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Lightbox from "react-image-lightbox";

import Button from "@components/Button";
import Thumbnail from "@components/Gallery/Thumbnail";

function SinglePerson({ person_data, float = "left" }) {
  const [fullListOpen, setFullListOpen] = useState(false);
  const [is_open, setIsOpen] = useState(false);
  const [photo_index, setPhotoIndex] = useState(0);

  const { personPhoto, personName, personProfession, personDescription } =
    person_data;

  let current_list = [];
  const fullImages = person_data?.certificates;

  if (person_data?.certificates) {
    current_list = fullListOpen
      ? person_data?.certificates
      : person_data?.certificates?.slice(0, 4);
  }
  const handleClickThumbnail = (id) => {
    setPhotoIndex(id);
    setIsOpen(true);
  };

  const image = getImage(personPhoto?.gatsbyImage);

  console.log(image);
  return (
    <>
      <div
        className={
          float === "right"
            ? "single-person single-person--right"
            : "single-person"
        }
      >
        <div className="single-person__image">
          {!!image && <GatsbyImage image={image} alt="" />}
        </div>
        <div className="single-person__text">
          <h3 className="single-person__name">{personName}</h3>
          <span className="single-person__qualification">
            {personProfession}
          </span>
          <div
            className="single-person__description"
            dangerouslySetInnerHTML={{ __html: personDescription }}
          />
        </div>
      </div>
      {person_data?.certificates?.length > 0 && (
        <div className="single-person__certificates">
          <div className="single-person__certificates-gallery">
            {current_list?.map((certificate, index) => {
              return (
                <Thumbnail
                  image={certificate?.sourceUrl}
                  key={index}
                  id={index}
                  onClick={() => handleClickThumbnail(index)}
                />
              );
            })}
          </div>
          {person_data?.certificates?.length > 4 && (
            <Button
              type="button"
              className="single-person__button"
              onClick={() => setFullListOpen(!fullListOpen)}
            >
              {fullListOpen ? "Pokaż mniej" : "Pokaż więcej certyfikatów"}
            </Button>
          )}
          {is_open && (
            <Lightbox
              mainSrc={fullImages[photo_index].sourceUrl}
              nextSrc={
                fullImages[(photo_index + 1) % fullImages.length].sourceUrl
              }
              prevSrc={
                fullImages[
                  (photo_index + fullImages.length - 1) % fullImages.length
                ].sourceUrl
              }
              onCloseRequest={() => setIsOpen(false)}
              onMovePrevRequest={() =>
                setPhotoIndex(
                  (photo_index + fullImages.length - 1) % fullImages.length
                )
              }
              onMoveNextRequest={() =>
                setPhotoIndex(
                  (photo_index + fullImages.length + 1) % fullImages.length
                )
              }
              enableZoom={false}
              imageCaption={
                <p>
                  {photo_index + 1} z {fullImages.length}
                </p>
              }
            />
          )}
        </div>
      )}
    </>
  );
}

export default SinglePerson;
